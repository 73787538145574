import { graphql, Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import PhotoPostListing from '../components/PhotoPostListing'

class TagRoute extends React.Component {
  render() {
    const { edges, totalCount } = this.props.data.allMarkdownRemark
    const title = this.props.data.site.siteMetadata.title
    const { tag } = this.props.pageContext
    const getLink = node => {
      switch (node.frontmatter.template) {
        case 'thought':
          return <Link to="/thoughts">{node.frontmatter.title}</Link>
        case 'photo':
          return <PhotoPostListing node={node} style={{ maxWidth: '15em' }} />
        case 'blog':
        default:
          return (
            <Link to={'/blog' + node.fields.slug}>{node.frontmatter.title}</Link>
          )
      }
    }
    const postLinks = edges.map(post => (
      <li key={post.node.id}>{getLink(post.node)}</li>
    ))

    return (
      <Layout location={this.props.location}>
        <Helmet title={title} />
        <h2 style={{ marginTop: 0 }}>
          {totalCount} post{totalCount !== 1 && 's'} tagged with “{tag}”
        </h2>
        <ul>{postLinks}</ul>
        <p>
          <Link to="/tags/">Browse all tags</Link>
        </p>
      </Layout>
    )
  }
}

export default TagRoute

export const pageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
            tagSlugs
          }
          frontmatter {
            title
            template
          }
        }
      }
    }
  }
`
